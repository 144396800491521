export const availabilityColors = [
  "#34A0FFFF",
  "#34A0FFEE",
  "#34A0FFDD",
  "#34A0FFCC",
  "#34A0FFBB",
  "#34A0FFAA",
  "#34A0FF99",
];

export const slotColors = [
  "#D2493CFF",
  "#D2493CEE",
  "#D2493CDD",
  "#D2493CCC",
  "#D2493CBB",
  "#D2493CAA",
  "#D2493C99",
];